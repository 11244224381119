import Article from 'components/Article';
import Layout from 'components/Layout';
import { graphql, PageProps } from 'gatsby';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import React from 'react';

interface DataProps {
    mdx: {
        frontmatter: {
            title: string;
            description: string;
        };
        body: string;
    };
}

const Page: React.FC<PageProps<DataProps>> = ({ path, data }) => {
    const { mdx } = data;
    const { frontmatter, body } = mdx;
    const { title, description } = frontmatter;

    const layoutProps = {
        path,
        title,
        description,
    };

    return (
        <Layout {...layoutProps}>
            <Article>
                <Article.Header>
                    <Article.Heading>{title}</Article.Heading>
                </Article.Header>

                <Article.BodyL>
                    <MDXRenderer>{body}</MDXRenderer>
                </Article.BodyL>

                <Article.Footer />
            </Article>
        </Layout>
    );
};

export default Page;

export const query = graphql`
    query ($id: String!) {
        mdx(id: { eq: $id }) {
            frontmatter {
                title
                description
            }
            body
        }
    }
`;
