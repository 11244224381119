import React, { HTMLAttributes, PropsWithChildren } from 'react';

/**
 * Top level container for the main article.
 *
 * Use it like this:
 *
 *     <Article>
 *         <Article.Header>
 *             <Article.Heading>...</Article.Heading>
 *         </Article.Header>
 *         <Article.Body>...</Article.Body>
 *         <Article.Footer>...</Article.Footer>
 *     </Article>
 *
 * Visually, the header and footer are unstyled, while the body appears in an
 * elevated box. Everything is centered and limited to a readable maximum width.
 */
const Article: React.FC<PropsWithChildren> = ({ children }) => {
    return (
        <article itemScope itemType="http://schema.org/Article">
            {children}
        </article>
    );
};

const ArticleHeader: React.FC<PropsWithChildren> = ({ children }) => {
    return (
        <header className="max-w-lg mx-auto mt-2 mb-5 px-4 px-md-5">
            {children}
        </header>
    );
};

const ArticleHeading: React.FC<
    PropsWithChildren<HTMLAttributes<HTMLHeadingElement>>
> = (props) => {
    const { className, children, ...rest } = props;
    return (
        <h1
            itemProp="headline"
            className={`mt-3 text-start text-md-center text-title ${
                className ?? ''
            }`}
            {...rest}>
            {children}
        </h1>
    );
};

const ArticleBodyMedium: React.FC<PropsWithChildren> = ({ children }) => {
    return (
        <div className="max-w-lg mx-auto px-md-5">
            <div className="px-md-4">
                <section
                    itemProp="articleBody"
                    className="body-bg-elevated p-4 px-md-5 rounded-3 text-break">
                    <div className="px-md-3 py-md-3">{children}</div>
                </section>
            </div>
        </div>
    );
};

const ArticleBodyLarge: React.FC<
    PropsWithChildren<HTMLAttributes<HTMLDivElement>>
> = (props) => {
    const { className, children, ...rest } = props;
    return (
        <section
            itemProp="articleBody"
            className={`bg-elevated rounded-3 text-break mx-auto max-w-lg my-5 px-4 py-3 px-md-5 py-md-4 ${
                className ?? ''
            }`}
            {...rest}>
            <div className="my-h">{children}</div>
        </section>
    );
};

const ArticleFooter: React.FC<PropsWithChildren> = ({ children }) => {
    return (
        <div className="px-md-5 mx-auto max-w-lg">
            <div className="px-md-4">{children}</div>
        </div>
    );
};

export default Object.assign(Article, {
    Header: ArticleHeader,
    Heading: ArticleHeading,
    BodyM: ArticleBodyMedium,
    BodyL: ArticleBodyLarge,
    Footer: ArticleFooter,
});
